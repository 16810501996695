import styled from '@emotion/styled'
import {
  subscriptionBreadcrumbsTranslationKeys,
  subscriptionUiStepsById,
  type SubscriptionBreadcrumb,
  type SubscriptionBreadcrumbsPath,
  type SubscriptionStepId,
} from '@orus.eu/dimensions'
import {
  Avatar,
  Button,
  Text,
  borderRadius,
  colorTokens,
  spacing,
  useTranslate,
  type CompoundIconName,
} from '@orus.eu/pharaoh'
import { memo } from 'react'
import { assert } from '../../../../lib/errors'
import { getBreadcrumbsRoots, getBreadcrumbsRootsChildren, getIconForNumber } from './breadcrumbs-tree'

type SubscriptionV2BreadcrumbsProps = {
  stepId: SubscriptionStepId
  goBackToBreadcrumbRootStep: (breadcrumb: SubscriptionBreadcrumb) => void
  className?: string
}

export const SubscriptionV2Breadcrumbs = memo<SubscriptionV2BreadcrumbsProps>(function SubscriptionV2Breadcrumbs({
  stepId,
  goBackToBreadcrumbRootStep,
  className,
}) {
  const { breadcrumbs } = subscriptionUiStepsById[stepId]

  const roots = getBreadcrumbsRoots()

  return (
    <BreadcrumbsContainer className={className}>
      {roots.map((name, currentIndex) => (
        <BreadcrumbsRoot
          key={name}
          breadcrumbs={breadcrumbs}
          currentIndex={currentIndex}
          goBackToBreadcrumbRootStep={goBackToBreadcrumbRootStep}
        />
      ))}
    </BreadcrumbsContainer>
  )
})

type BreadcrumbsItemVariant = 'done' | 'current' | 'future'

type BreadcrumbsRootProps = {
  currentIndex: number
  breadcrumbs: SubscriptionBreadcrumbsPath
  goBackToBreadcrumbRootStep: (breadcrumb: SubscriptionBreadcrumb) => void
}
const BreadcrumbsRoot = memo<BreadcrumbsRootProps>(function BreadcrumbsRoot({
  breadcrumbs,
  goBackToBreadcrumbRootStep,
  currentIndex,
}) {
  const translate = useTranslate()
  const roots = getBreadcrumbsRoots()
  const activeIndex = roots.indexOf(breadcrumbs[0])
  assert(activeIndex >= 0, 'Active root not found in breadcrumbs')
  const variant = getBreadcrumbsItemVariant({ currentIndex, activeIndex })

  const icon: CompoundIconName = variant === 'done' ? 'circle-check-solid' : getIconForNumber(currentIndex + 1)

  const childrenToDisplay =
    variant === 'current' && breadcrumbs.length > 1 ? (getBreadcrumbsRootsChildren()[breadcrumbs[0]] ?? []) : []

  const selectedBreadcrumbChildIndex = childrenToDisplay.findIndex((breadcrumb) => breadcrumb === breadcrumbs[1])

  return (
    <div>
      <BreadcrumbLabelButton
        variant="text"
        fullWidth
        disabled={variant === 'future'}
        onClick={() => goBackToBreadcrumbRootStep(roots[currentIndex])}
      >
        <Avatar
          variant="full"
          size="30"
          icon={icon}
          color={
            variant === 'future' ? colorTokens['color-fg-base-disable'] : colorTokens['color-fg-base-active-inverse']
          }
        />
        <Text
          variant="body2Medium"
          color={variant === 'future' ? colorTokens['color-text-base-disable'] : colorTokens['color-text-base-main']}
        >
          {translate(subscriptionBreadcrumbsTranslationKeys[roots[currentIndex]])}
        </Text>
      </BreadcrumbLabelButton>

      {childrenToDisplay.length > 0 ? (
        <BreadcrumbsRowChildren>
          {childrenToDisplay.map((childName, index) => (
            <BreadcrumbsRowChild
              key={childName}
              textVariant="body2"
              variant="text"
              onClick={() => goBackToBreadcrumbRootStep(childName)}
              disabled={index > selectedBreadcrumbChildIndex}
            >
              {translate(subscriptionBreadcrumbsTranslationKeys[childName])}
            </BreadcrumbsRowChild>
          ))}
        </BreadcrumbsRowChildren>
      ) : (
        <></>
      )}
    </div>
  )
})

function getBreadcrumbsItemVariant({
  currentIndex,
  activeIndex,
}: {
  currentIndex: number
  activeIndex: number
}): BreadcrumbsItemVariant {
  if (currentIndex < activeIndex) return 'done'
  if (currentIndex === activeIndex) return 'current'
  return 'future'
}

const BreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const BreadcrumbsRowChildren = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing[30]};
  padding: 0 ${spacing[30]};
  margin-left: 28px;
  margin-top: ${spacing[30]};
`

const BreadcrumbsRowChild = styled(Button)(() => ({
  textDecoration: 'unset',
  height: 'auto',
  color: colorTokens['color-text-base-main'],

  '&:focus-visible': {
    textDecoration: 'underline',
    outline: 'none',
  },

  '&:disabled': {
    color: colorTokens['color-text-base-disable'],
  },
}))

const BreadcrumbLabelButton = styled(Button)(() => ({
  height: '44px',
  justifyContent: 'flex-start',
  padding: `${spacing[40]} ${spacing[30]}`,
  textDecoration: 'unset',
  borderRadius: borderRadius[20],

  '&:hover': {
    backgroundColor: colorTokens['color-bg-base-hover'],
  },

  '&:focus-visible': {
    outline: `1px solid ${colorTokens['color-text-base-main']}`,
    backgroundColor: colorTokens['color-bg-base-hover'],
  },

  '& > div > span': {
    display: 'flex',
    alignItems: 'center',
    gap: spacing[40],
  },
}))
