import type { OperatingZone } from '@orus.eu/operating-zone'
import { isProductionEnvironment } from './env-utils'

let gtmLoaded = false

/**
 * Ensure GTM is loaded.
 *
 * In order to have one GTM setup per operating zone, we made the following tradeoffs:
 * - We will only use GTM from the subscription funnel
 * - We will only load the GTM instance for the first operating zone encountered
 * - Edge cases where a prospect would navigate to a different operating zone will have an unspecified behavior
 *
 * GTM is only loaded in production
 */
export function ensureGTMLoaded(operatingZone: OperatingZone): void {
  if (gtmLoaded) {
    return
  }

  if (!isProductionEnvironment()) {
    return
  }

  gtmLoaded = true

  const gtmInstance = gtmInstances[operatingZone]

  const iframe = document.createElement('iframe')
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmInstance}`
  iframe.height = '0'
  iframe.width = '0'
  iframe.style.display = 'none'
  iframe.style.visibility = 'hidden'

  const body = document.body
  if (body.firstChild) {
    body.insertBefore(iframe, body.firstChild)
  } else {
    body.appendChild(iframe)
  }

  // The code below is the initialization script copy / pasted from GTM backoffice
  // Which is why it's not typescript and doesn't follow our code style

  window.dataLayer = window.dataLayer || []
  ;(function (w, d, s, l, i) {
    // @ts-expect-error: copy / pasted from GTM setup
    w[l] = w[l] || []
    // @ts-expect-error: copy / pasted from GTM setup
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : ''
    // @ts-expect-error: copy / pasted from GTM setup
    j.async = true
    // @ts-expect-error: copy / pasted from GTM setup
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
    // @ts-expect-error: copy / pasted from GTM setup
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', gtmInstance)
}

const gtmInstances: Record<OperatingZone, string> = {
  fr: 'GTM-WVR93K7',
  es: 'GTM-5LQDS3BG',
}
